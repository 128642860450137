import { UseMutationResult } from '@tanstack/react-query'

import { Button } from '../../../main/components/button'
import {
  Dialog,
  DialogBody,
  DialogDismiss,
  DialogFooter,
  DialogHeader,
  useDialogState,
} from '../../../main/components/dialog'
import { ErrorView } from '../../../main/components/error-view'
import { Loading } from '../../../main/components/loading'
import { SocialShare } from '../../../main/components/social-share/SocialShare'

interface ShareResponse {
  imgUrl: string
  shareUrl: string
}

interface ShareProps<ResponseType> {
  state: UseMutationResult<ResponseType | undefined, any, any>
  onShareClick: () => void
  className?: string
  disabled?: boolean
  base64ImageData: string | null
}

export function Share<ResponseType extends ShareResponse>(props: ShareProps<ResponseType>) {
  const dialogState = useDialogState()

  return (
    <>
      <Button
        leftContent="share"
        theme="transparent"
        className={props.className}
        onClick={() => {
          props.onShareClick()
          dialogState.show()
        }}
      >
        Share map
      </Button>

      <Dialog state={dialogState} aria-label="Share map">
        <DialogHeader className="text-sm">Share map</DialogHeader>
        <DialogBody className="flex w-145 max-w-full flex-col">
          <ShareDialogBody
            isError={props.state.isError}
            isLoading={props.state.isLoading}
            data={props.state.data}
            base64ImageData={props.base64ImageData}
          />
        </DialogBody>
        <DialogFooter>
          <Button as={DialogDismiss}>Close</Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

interface ShareDialogBodyProps<ResponseType> {
  isError: boolean
  isLoading: boolean
  data?: ResponseType
  base64ImageData: string | null
}

function ShareDialogBody<ResponseType extends ShareResponse>(props: ShareDialogBodyProps<ResponseType>) {
  if (props.isLoading) {
    return (
      <div className="flex h-110 items-center justify-center">
        <Loading />
      </div>
    )
  }

  if (props.isError || !props.data) {
    return (
      <div className="h-110">
        <ErrorView
          showRefresh={false}
          title="Map share failed"
          message={
            <>
              There’s been an error sharing the map.
              <br />
              Please try sharing the map again and if the problem persists, please contact support.
            </>
          }
        />
      </div>
    )
  }

  return (
    <>
      <img alt={document.title} src={props.data.imgUrl} className="w-full" />
      <div className="mt-4">
        <SocialShare
          shareUrl={props.data.shareUrl}
          title={document.title}
          imageToDownloadURL={props.data.imgUrl}
          imageToDownloadBase64Data={props.base64ImageData}
        />
      </div>
    </>
  )
}
